/* - - - - - - - - - - - - - -       init AOS       - - - - - - - - - - - - - - - */
setTimeout( function() {
	AOS.init({
		// startEvent: 'load',
		duration: 1000,
		offset: 30,
		once: true
	});
}, 300 );

$( document ).ready( function() {
	/* - - - - - - - - - - - - - -       input validate       - - - - - - - - - - - - - - - */
	function setInput() {
		$( 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]), textarea' ).each( function() {
			const $parent = $( this ).closest( '.form-group' );
			if ( $( this ).val() !== '' ) {
				$parent.addClass( 'filled' );
			}
		});
	}
	setInput();
	$( 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"])' ).on( 'input', function() {
		const $parent = $( this ).closest( '.form-group' );
		if ( $( this ).val() !== '' ) {
			$parent.addClass( 'filled' );
		} else {
			if ( $( this ).attr( 'type' ) !== 'tel' ) {
				$parent.removeClass( 'filled' );
			}
		}
	});
	// $( '[type="tel"]' ).on( 'mouseenter focus', function() {
	// 	const $parent = $( this ).closest( '.form-group' );
	// 	$parent.addClass( 'filled' );
	// });
	// $( '[type="tel"]' ).on( 'mouseleave', function() {
	// 	const $parent = $( this ).closest( '.form-group' );
	// 	if ( $( this ).val() === '' && !$( this ).is( ':focus' )) {
	// 		$parent.removeClass( 'filled' );
	// 	}
	// });
	// $( '[type="tel"]' ).on( 'blur', function() {
	// 	const $parent = $( this ).closest( '.form-group' );
	// 	if ( $( this ).val() === '' ) {
	// 		$parent.removeClass( 'filled' );
	// 	}
	// });
	$( 'textarea' ).on( 'input', function() {
		const $parent = $( this ).closest( '.form-group' );
		if ( $( this ).val() !== '' ) {
			$parent.addClass( 'filled' );
		} else {
			$parent.removeClass( 'filled' );
		}
		// this.style.height = "auto";
		// this.style.height = (this.scrollHeight) + "px";
	});

	/* - - - - - - - - - - - - - -       resize menu       - - - - - - - - - - - - - - - */
	// function resizeMenu() {
	// 	$( '.top-menu li' ).each( function () {
	// 		const $this = $( this ),
	// 			$link = $this.find( 'a' );
	// 		$this.css( 'width', $link.innerWidth() + 5 + 'px' );
	// 	});
	// }
	// resizeMenu();
	// $( window ).on( 'resize', resizeMenu );

	/* - - - - - - - - - - - - - -       scroll to       - - - - - - - - - - - - - - - */
	if ( window.location.hash !== '' ) {
		setTimeout( function () {
			$( 'html, body' ).animate({ scrollTop: $( window.location.hash ).offset().top - $( '.header' ).innerHeight() + 'px' }, 500 );
		}, 300 );
	}
	$( document ).on( 'click', '.js-scroll-to', function( e ) {
		e.preventDefault();
		const link = $( this ).attr( 'href' );
		if ( window.location.pathname === '' || window.location.pathname === '/' ) {
			$( 'html, body' ).animate({ scrollTop: $( link ).offset().top - $( '.header' ).innerHeight() + 'px' }, 500 );
			setTimeout( function () {
				$( 'html, body' ).animate({ scrollTop: $( link ).offset().top - $( '.header' ).innerHeight() + 'px' }, 100 );
			}, 500 );
		} else {
			window.location.href = window.location.origin + link;
		}
		if ( window.matchMedia( '(max-width: 1199px)' ).matches ) {
			$( 'body' ).removeClass( 'fixed' );
			$( '.menu-toggle' ).removeClass( 'active' );
			$( '.top-menu' ).removeClass( 'show' );
		}
	});

	/* - - - - - - - - - - - - - -       set active for menu       - - - - - - - - - - - - - - - */
	function setActiveMenu() {
		const top = $( window ).scrollTop();
		let $activeLink = '';
		let minTop = 10000;

		$( '.top-menu li a' ).each( function() {
			const $this = $( this ),
				link = $( this ).attr( 'href' ),
				offset = $( '.header' ).innerHeight() + 15;
			if ( $( link ).length ) {
				const posStart = $( link ).offset().top - offset,
					posEnd = posStart + $( link ).innerHeight();
				if ( posStart <= top && posEnd > top ) {
					$( '.top-menu li a' ).removeClass( 'active' );
					$this.addClass( 'active' );
					$activeLink = $this;
				}

				if ( minTop > posStart ) {
					minTop = posStart;
				}
			}
		});
		if ( minTop > top ) {
			$( '.top-menu li a' ).removeClass( 'active' );
		}
	}
	setActiveMenu();
	$( window ).on( 'scroll', setActiveMenu );

	$( document ).on( 'click', '.js-menu-toggle', function() {
		if ( !$( this ).hasClass( 'active' ) ) {
			$( 'body' ).addClass( 'fixed' );
			$( this ).addClass( 'active' );
			$( '.top-menu' ).addClass( 'show' );
		} else {
			$( 'body' ).removeClass( 'fixed' );
			$( this ).removeClass( 'active' );
			$( '.top-menu' ).removeClass( 'show' );
		}
	});

	$( window ).on( 'resize', function () {
		if ( window.matchMedia( '(min-width: 1200px)' ).matches ) {
			$( 'body' ).removeClass( 'fixed' );
			$( '.menu-toggle' ).removeClass( 'active' );
			$( '.top-menu' ).removeClass( 'show' );
		}
	});

	/* - - - - - - - - - - - - - -       init fancybox       - - - - - - - - - - - - - - - */
	function initFancybox() {
		$( '[data-fancybox]' ).fancybox({
			closeExisting: true,
			animationEffect: 'fade',
			touch: false,
			autoFocus: false,
			buttons: [
				'slideShow',
				'close'
			],
			smallBtn: true,
			btnTpl: {
				smallBtn:
					'<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
					'<span class="icon icon-i-close"></span>' +
					'</button>'
			}
		});
	}
	initFancybox();

	/* - - - - - - - - - - - - - -       init about slider       - - - - - - - - - - - - - - - */
	$( '.js-about-slider' ).slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		fade: true,
		swipeToSlide: true
	}).on( 'beforeChange', function (event, slick, currentSlide, nextSlide) {
		$( '.about-inner > .title h2' ).html( $( '.about-slider [data-slick-index="' + nextSlide + '"] [class*="heading2"]' ).html() );
	});


	/* - - - - - - - - - - - - - -       rebuild Dishes       - - - - - - - - - - - - - - - */
	function rebuildDishes() {
		$( '.dishes-box' ).each( function () {
			const $this = $( this ),
				$link = $this.find( '.dishes-box__link' ).detach();
			if ( window.matchMedia( '(max-width: 991px)' ).matches ) {
				$this.find( '.dishes-box__head' ).append( $link );
			} else {
				$this.find( '.dishes-box__body' ).append( $link );
			}
		});
	}
	rebuildDishes();
	$( window ).on( 'resize', rebuildDishes );

	/* - - - - - - - - - - - - - -       init dishes slider       - - - - - - - - - - - - - - - */
	let dishesSlider,
		dishesThumb;
	function initDishesSlider() {
		dishesSlider = $( '.js-dishes-slider' ).slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 100,
			arrows: false,
			dots: false,
			swipe: false,
			touchMove: false,
			swipeToSlide: true,
			fade: true,
			asNavFor: '.js-dishes-thumb'
		}).on( 'beforeChange', function ( event, slick, currentSlide, nextSlide ) {
			$( '.dishes-catalog__slider [data-aos]' ).css( 'transition-duration', '0s' ).removeClass( 'aos-animate' );
		}).on( 'afterChange', function ( event, slick, currentSlide ) {
			$( '.dishes-catalog__slider [data-aos]' ).css( 'transition-duration', '' );
			const $slide = $( '.dishes-catalog__slider .slick-slide[data-slick-index="' + currentSlide + '"]' );
			$slide.find( '[data-aos]' ).addClass( 'aos-animate' );
		});
		dishesThumb = $( '.js-dishes-thumb' ).slick({
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			arrows: false,
			dots: false,
			swipeToSlide: true,
			focusOnSelect: true,
			asNavFor: '.js-dishes-slider'
		});
	}

	$.getJSON( 'food.json', function() {

	}).done( function( result ) {
		// console.log( 'success' );
		const food = result.food;

		if ( food.length ) {
			const $parent = $( '.dishes-content' );

			let tabsTpl = '',
				firstTab = '',
				tabsListTpl = '';

			let catalogTpl = '',
				catalogBoxTpl = '';

			food.map( ( item, i ) => {
				if ( i === 0 ) {
					firstTab = item.category;
				}

				tabsListTpl += '<li class="' + `${( i === 0 ) ? 'active' : ''}` +  '" data-tab="tab-' + item.id + '">' +
					item.category +
				'</li>';

				let catalogSliderTpl = '',
					catalogThumbTpl = '';
				if ( item.items.length ) {
					item.items.map( ( element, j ) => {
						const modalId = item.id + '-' + element.id;
						let catalogModalTpl = '',
							catalogModalInfoTpl = '',
							catalogModalTableTpl = '',
							catalogModalTableHeadTpl = '',
							catalogModalTableBodyTpl = '';

						element.descLong.information.map( info => {
							catalogModalInfoTpl +=
								'<div class="dishes-box__modal-info-box">' +
									info +
								'</div>';
						});
						element.descLong.table.heading.map( th => {
							catalogModalTableHeadTpl +=
								'<th>' +
									th +
								'</th>';
						});
						element.descLong.table.items.map( tr => {
							let trTpl = '';
							tr.map( td => {
								trTpl += '<td>' +
										td +
									'</td>';
							});
							catalogModalTableBodyTpl +=
								'<tr>' +
									trTpl +
								'</tr>';
						});
						catalogModalTableTpl =
							'<table>' +
								'<thead>' +
									'<tr>' +
										catalogModalTableHeadTpl +
									'</tr>' +
								'</thead>' +
								'<tbody>' +
									catalogModalTableBodyTpl +
								'</tbody>' +
							'</table>';
						catalogModalTpl =
							'<div id="dishes-' + modalId + '" class="dishes-box__modal modal">' +
								'<div class="dishes-box__modal-content">' +
									'<div class="dishes-box__modal-title">' + element.descLong.heading + '</div>' +
									'<div class="dishes-box__modal-subtitle">' + element.descLong.subheading + '</div>' +
									'<div class="dishes-box__modal-desc">' +
										'<div class="dishes-box__modal-desc-img">' +
											'<picture>' +
												'<source srcset="' + element.image + '">' +
												'<img src="' + element.image + '" alt="' + element.alt + '">' +
											'</picture>' +
										'</div>' +
										'<div class="dishes-box__modal-desc-text">' + element.descLong.recipe + '</div>' +
									'</div>' +
									'<div class="dishes-box__modal-text">' + element.descLong.ingredients + '</div>' +
									'<div class="dishes-box__modal-other">' +
										'<div class="dishes-box__modal-info">' +
											catalogModalInfoTpl +
										'</div>' +
										'<div class="dishes-box__modal-table">' +
											catalogModalTableTpl +
										'</div>' +
									'</div>' +
									'<div class="dishes-box__modal-contacts">' + element.descLong.contacts + '</div>' +
								'</div>' +
							'</div>';

						catalogSliderTpl += '<div>' +
							'<div class="dishes-box">' +
								'<div class="dishes-box__body">' +
									'<div class="dishes-box__title" data-aos="fade-up">' + element.heading + '</div>' +
									'<div class="dishes-box__text" data-aos="fade-up" data-aos-delay="200">' + element.descShort + '</div>' +
									'<div class="dishes-box__link" data-aos="fade-up" data-aos-delay="400">' +
										'<a href="#dishes-' + modalId + '" class="btn btn-sm" data-fancybox>' + element.button + '</a>' +
									'</div>' +
								'</div>' +
								'<div class="dishes-box__head">' +
									'<div class="dishes-box__img" data-aos="zoom-in" data-aos-delay="200">' +
										'<picture>' +
											'<source srcset="' + element.image + '">' +
											'<img src="' + element.image + '" alt="' + element.alt + '"/>' +
										'</picture>' +
									'</div>' +
								'</div>' +
								catalogModalTpl +
							'</div>' +
						'</div>';

						catalogThumbTpl += '<div>' +
							'<div class="dishes-catalog__thumb-box">' +
								'<picture>' +
									'<source src="' + element.image + '">' +
									'<img src="' + element.image + '" alt="' + element.alt + '"/>' +
								'</picture>' +
							'</div>' +
						'</div>';
					});
				}
				catalogBoxTpl += '<div class="dishes-catalog__box ' + `${( i === 0 ) ? 'show' : ''}` +  '" data-tab="tab-' + item.id + '">' +
					'<div class="dishes-catalog__slider js-dishes-slider">' +
						catalogSliderTpl +
					'</div>' +
					'<div class="dishes-catalog__thumb js-dishes-thumb" data-aos="fade-up" data-aos-delay="200">' +
						catalogThumbTpl +
					'</div>' +
				'</div>';
			});

			tabsTpl = '<div class="dishes-tabs">' +
				'<div class="dishes-tabs__toggle js-dishes-tabs">' +
					'<div class="dishes-tabs__title">' +
						firstTab +
					'</div>' +
					'<span class="icon icon-i-arr-down"></span>' +
				'</div>' +
				'<div class="dishes-tabs__list">' +
					'<ul>' +
						tabsListTpl +
					'</ul>' +
				'</div>' +
			'</div>';
			$parent.append( tabsTpl );

			catalogTpl = '<div class="dishes-catalog">' +
				catalogBoxTpl +
			'</div>';
			$parent.append( catalogTpl );

			initDishesSlider();
			rebuildDishes();
			initFancybox();
		}
	}).fail( function() {
		// console.log( 'error' );
	}).always( function() {
		// console.log( 'complete' );
	});

	$( document ).on( 'click', '.dishes-tabs li', function() {
		const $this = $( this );
		if ( !$this.hasClass( 'active' ) ) {
			$( '.dishes-tabs li' ).removeClass( 'active' );
			$this.addClass( 'active' );
			$( '.dishes-catalog__box' ).removeClass( 'show' );
			$( '.dishes-catalog__box[data-tab="' + $this.attr( 'data-tab' ) + '"]' ).addClass( 'show' );
			dishesSlider.slick( 'refresh' );
			dishesThumb.slick( 'refresh' );
		}
	});

	/* - - - - - - - - - - - - - -       dishes tabs toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.js-dishes-tabs', function() {
		const $this = $( this ),
		$parent = $this.closest( '.dishes-tabs' );
		if ( !$parent.hasClass( 'show' ) ) {
			$parent.addClass( 'show' );
			$( '.dishes-tabs__list' ).slideDown( 300 );
		} else {
			$parent.removeClass( 'show' );
			$( '.dishes-tabs__list' ).slideUp( 300 );
		}
	});
	$( document ).click( function( e ) {
		if ( $( e.target ).closest( '.dishes-tabs__toggle' ).length ) {
			return;
		}
		if ( window.matchMedia( '(max-width: 991px)' ).matches ) {
			$( '.dishes-tabs' ).removeClass( 'show' );
			$( '.dishes-tabs__list' ).slideUp( 300 );
		}
	});
	$( document ).on( 'click', '.dishes-tabs__list li', function() {
		$( '.dishes-tabs__title' ).html( $( this ).html() );
		if ( window.matchMedia( '(max-width: 991px)' ).matches ) {
			$( this ).closest( '.dishes-tabs' ).removeClass( 'show' );
			$( '.dishes-tabs__list' ).slideUp( 300 );
		}
	});
	$( window ).on( 'resize', function() {
		if ( window.matchMedia( '(min-width: 992px)' ).matches ) {
			$( '.dishes-tabs' ).removeClass( 'show' );
			$( '.dishes-tabs__list' ).css( 'display', '' );
		}
	});

	/* - - - - - - - - - - - - - -       init company banner       - - - - - - - - - - - - - - - */
	const $companyBanner = $( '.js-company-banner' );
	$companyBanner.on( 'init', function () {
		// $( '.company-banner .slick-slide[data-slick-index="0"] .company-banner__box-img img' ).addClass( 'animate' );
	});
	$companyBanner.slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 100,
		arrows: false,
		dots: true,
		fade: true,
		swipeToSlide: true
	}).on( 'beforeChange', function ( event, slick, currentSlide, nextSlide ) {
		$( '.company-banner__box-img img' ).css( 'transition-duration', '0s' ).removeClass( 'animate' );
		$( '.company-banner [data-aos]' ).css( 'transition-duration', '0s' );
		$( '.company-banner__box-title, .company-banner__box-text' ).removeClass( 'aos-animate' );
	}).on( 'afterChange', function ( event, slick, currentSlide ) {
		$( '.company-banner [data-aos]' ).css( 'transition-duration', '' );
		const $slide = $( '.company-banner .slick-slide[data-slick-index="' + currentSlide + '"]' );
		setTimeout( function () {
			$slide.find( '.company-banner__box-img img' ).css( 'transition-duration', '' ).addClass( 'animate' );
		}, 100 );
		$slide.find( '.company-banner__box-title, .company-banner__box-text' ).addClass( 'aos-animate' );
	});

	/* - - - - - - - - - - - - - -       init company slider       - - - - - - - - - - - - - - - */
	$( '.js-company-slider' ).slick({
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	/* - - - - - - - - - - - - - -       technologies toggle       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.js-technologies', function( e ) {
		e.preventDefault();
		const $parent = $( this ).closest( '.technologies-box' );
		if ( !$parent.hasClass( 'show' ) ) {
			$( '.technologies-box' ).removeClass( 'show' ).addClass( 'js-technologies' );
			$parent.addClass( 'show' ).removeClass( 'js-technologies' );
			$( '.technologies-box__link' ).slideDown( 300 );
			$( '.technologies-box__text' ).slideUp( 300 );
			$parent.find( '.technologies-box__link' ).slideUp( 300 );
			$parent.find( '.technologies-box__text' ).slideDown( 300 );
		}
	});

	/* - - - - - - - - - - - - - -       technologies close       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.js-technologies-close', function() {
		$( '.technologies-box' ).removeClass( 'show' ).addClass( 'js-technologies' );
		$( '.technologies-box__link' ).slideDown( 300 );
		$( '.technologies-box__text' ).slideUp( 300 );
	});

	/* - - - - - - - - - - - - - -       send form       - - - - - - - - - - - - - - - */
	$( document ).on( 'click', '.js-send-form', function() {
		let validate = true;
		const $form = $( '#' + $( this ).attr( 'rel' ) );
		$form.find( '.validate' ).each( function() {
			const $this = $( this ),
				$parent = $this.closest( '.form-group' );
			if ( $this.attr( 'type' ) === 'checkbox' ) {
				if ( !$this.prop( 'checked' ) ) {
					validate = false;
					$this.focus();
					$this.closest( '.form-checkbox' ).addClass( 'has-error' );
				} else {
					$this.closest( '.form-checkbox' ).removeClass( 'has-error' );
				}
			} else if ( $this.val() === '' ) {
				validate = false;
				$this.focus();
				$parent.addClass( 'has-error' );
			} else {
				$parent.removeClass( 'has-error' );
			}
		});
		if ( validate ) {
			$( '.contacts-loader' ).addClass( 'show' );
			$.ajax({
				url: 'ajax.php',
				data: 'action=send_form&' + $form.serialize(),
				success: function( data ) {
                    $( '.contacts-loader' ).removeClass( 'show' );
				    if ($form.hasClass('sideform-form')) {
                        $( '.sideform .contacts-success' ).addClass( 'show' );
                    } else {
                        $( '.contacts .contacts-success' ).addClass( 'show' );
                        if ( window.matchMedia( '(max-width: 767px)' ).matches ) {
                            $( 'html, body' ).animate({ scrollTop: $( '.contacts' ).offset().top - $( '.header' ).innerHeight() + 'px' }, 300);
                        }
                    }
					setTimeout( function() {
                        if ($form.hasClass('sideform-form')) {
                            $( '.sideform .contacts-success' ).removeClass( 'show' );
                        }
                        else {
                            $( '.contacts .contacts-success' ).removeClass( 'show' );
                        }
						$form[ 0 ].reset();
						$form.find( '.form-group' ).removeClass( 'filled' );
					}, 5000 );
				}
			});
		} else {
			// console.log('Error')
		}
	});

	/* - - - - - - - - - - - - - -       animation image       - - - - - - - - - - - - - - - */
	function translateImage( target ) {
		if( $( target ).length ) {
			const top = $( window ).scrollTop();
			const windowHeight = $( window ).innerHeight();
			const thisTop = $( target ).offset().top;
			const imageHeight = target.innerHeight();
			const pointStart = thisTop - windowHeight;

			const shiftTop = imageHeight / 2;
			// let shiftTop = windowHeight / 5;
			// if ( shiftTop > 150 ) {
			// 	shiftTop = 150;
			// }
			let currentShift = 0;
			$( target ).css({
				'margin-top': imageHeight / 3 + 'px'
			});
			if ( top < pointStart ) {
				$( target ).css({
					'transform': 'translateY( 0 )'
				});
			} else if ( top > thisTop ) {
				$( target ).css({
					'transform': 'translateY( -' + shiftTop + 'px )'
				});
			} else {
				$( target ).addClass( 'show' );
				currentShift = shiftTop * (1 - (thisTop - top) / windowHeight);
				$( target ).css({
					'transform': 'translateY( -' + currentShift + 'px )'
				});
			}
		}
	}

	function sideForm(){
	    const form = $('.js-sideform');
        const formBtn = $('.js-formbtn');
        const formClose = $('.js-sideform-close');
        const formChange = $('.js-sideform-change');
        formBtn.click(function () {
            formBtn.addClass('active');
            form.addClass('active');
        });
        formClose.click(function () {
            formBtn.removeClass('active');
            form.removeClass('active');
        });
        formChange.click(function () {
            $( '.sideform .contacts-success' ).removeClass( 'show' );
            $('#sideform')[0].reset().find( '.form-group' ).removeClass( 'filled' );
        });
    }
    sideForm();

    function formSelect() {
        const selects = document.querySelectorAll('.js-form-select');

        selects.forEach(select => {
            const selectBtn = select.querySelector('.form-select-dropdown span');

            select.addEventListener('click', function () {
                this.classList.toggle('active');
            });

            selectBtn.addEventListener('click', function () {
                const selectedOption = this.dataset.val;
                selectBtn.removeAttribute('data-selected');
                this.setAttribute('data-selected', '');
                this.closest('.form-select').querySelector('input').value = selectedOption;
            });

            document.addEventListener('mouseup', function (e) {
                if (!select.contains(e.target)) {
                    select.classList.remove('active');
                }
            });
        });
    }

    formSelect();
	$( window ).on( 'scroll', function() {
		$( '.js-parallax' ).each(function () {
			translateImage( $( this ) );
		});
	});
});
